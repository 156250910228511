import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { graphql, useStaticQuery } from "gatsby";

import Main from "../components/Main";

import { H3, SectionHeader } from "../components/Typo";
import QuoteBox from "../components/QuoteBox";
import Section from "../components/Section";
import MediaBottom from "../components/MediaBottom";
import VideoCarousel from "../components/VideoCarousel";
import { VideoModal } from "../components/VideoModal";

import photo75 from "../images/75.jpeg";

import YouTube from "simple-youtube-api";

// eslint-disable-next-line
import { squareImage } from "../image";

const Column = styled.div`
  flex: 1 1 50%;

  &:first-child {
    margin-right: 64px;

    ${media.lessThan("medium")`
      margin-right: 0px;
      margin-bottom: 24px;
    `}
  }
`;

const Prices = styled(Column)`
  position: relative;
  padding: 32px 48px;
  border-radius: 2px;
  align-self: center;
  flex: 1 1 auto;

  ${media.lessThan("medium")`
  top: 0px;
  padding: 24px;
  align-self: stretch;
    `}

  background-color: rgba(109, 190, 159, 0.06);
  color: #294c41;
`;

const Description = styled(Section)`
  max-width: 960px;
  margin: 0 auto;
  margin-top: 32px;

  & > img {
    ${media.lessThan("medium")`
      display: none;
  `}
  }

  ${media.lessThan("medium")`
      flex-direction: column;
      margin-top: 32px;
    `}
`;

const PlaylistSection = styled(Section)`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 76px;

  ${media.lessThan("medium")`
    margin-top: 56px;
  `}
`;

const Terms = styled.div`
  background-color: var(--greybg);
  font-weight: 300;
  padding: 40px 45px;
  border-radius: 2px;

  & > ul {
    font-size: 0.875rem;
    padding-left: 1rem;

    > li {
      margin-bottom: 0.5rem;
      list-style-type: disc;
    }
  }
`;

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0px 16px;
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      photo: file(relativePath: { eq: "1.jpg" }) {
        ...squareImage
      }
    }
  `);

  const [videos, setVideos] = React.useState(null);

  const [videoId, setVideoId] = React.useState(null);

  React.useEffect(() => {
    const yt = new YouTube("AIzaSyAb8HpPh9mGryfQBGA6ymVc7AnClLXojIs");

    yt.getPlaylist(
      "https://www.youtube.com/playlist?list=PLfRJRCjdGa87Ik07qTPTIubICiTYAItNm"
    )
      .then((x) => x.getVideos(10))
      .then(setVideos);
  }, []);

  return (
    <Main title="Zangles in Amersfoort" path="/zangles">
      <VideoModal videoId={videoId} onClose={() => setVideoId(null)} />
      <QuoteBox
        style={{
          maxWidth: 960,
          margin: "auto",
          boxSizing: "border-box",
          marginBottom: 80,
        }}
        padding={"0px 76px"}
        quote={`"VUL JE LONGEN MET LUCHTIGHEID EN ZING ZE UIT JE LIJF!"`}
        source="Emma Lou"
      />
      <Container>
        <SectionHeader
          title="Zing met vertrouwen en plezier!"
          icon="microphone"
          noMargin
          small
        />
      </Container>
      <Description flex>
        <Column>
          <p style={{ marginTop: 0 }}>
            Wil je graag zingen, je techniek verbeteren of met meer
            zelfvertrouwen je stem laten horen? Wat je doel ook is, ik help je
            graag verder!
          </p>
          <p>
            In mijn zanglessen werk ik samen met jou aan alles wat je nodig hebt
            om jouw muzikaliteit optimaal te ontwikkelen. Denk aan performance,
            tekstbeleving, gezond stemgebruik, techniek, timing, intonatie,
            sound en het ontdekken van de muziekstijl die bij jou past. Vrij
            zingen zonder schaamte of blokkades speelt een belangrijke rol in
            mijn lessen.
          </p>
        </Column>
        <Column>
          <img
            alt=""
            src={photo75}
            style={{
              width: "100%",
              maxWidth: 400,
              margin: "auto",
              display: "block",
            }}
          />
        </Column>
      </Description>

      <Section>
        <SectionHeader title="Groepsles zang" noMargin small />
        <p>
          Om de week op maandagavond van 19:30 tot 20:30 kun je meedoen met de
          groepsles zang. Het doel? Gewoon lekker zingen! Geen verplichtingen,
          geen optredens—puur samen zingen omdat het fijn is. Ondertussen leer
          je spelenderwijs over samenklank, timing en meerstemmig zingen.
        </p>
        <p>Kom je een keer meedoen? Je bent van harte welkom!</p>
      </Section>

      <Section noMargin flex style={{ marginTop: 76 }}>
        <Prices>
          <H3 style={{ color: "currentColor" }}>Tarieven</H3>
          <p>Proefles: €20,-</p>
          <p>Individuele les: €32,-</p>
          <p>Duoles: €20,- p.p.</p>
          <p>Groepsles (1 uur): €12,50 p.p.</p>
        </Prices>
        <Column>
          <p>
            <strong>Adres</strong>
            <address>Paulus Borstraat 37, Amersfoort</address>8 minuten lopen
            vanaf Amersfoort Centraal
            <br />
          </p>
          <p>
            <strong>Parkeren</strong>
            <br />
            Gratis in de vakken voor de deur mits er plek is. In de straat is
            voor vergunninghouders. Op de Noorderwierweg en Soesterweg is het
            gratis, maar let op de borden want een deel is daar nog betaald
            parkeren.
          </p>

          <p>
            Aanmelden via <a href="mailto:info@emmalou.nl">info@emmalou.nl</a>
          </p>
        </Column>
      </Section>

      <PlaylistSection>
        <SectionHeader title="Videos" center noMargin />

        {videos && <VideoCarousel items={videos} onClick={setVideoId} />}
      </PlaylistSection>

      <Section flex style={{ marginTop: 76 }}>
        <Column>
          <Terms>
            <H3>Voorwaarden zangles</H3>
            <ul>
              <li>
                Een zangles duurt 40 minuten. Dit is inclusief binnenkomen,
                afspraken maken en repertoire uitzoeken.
              </li>
              <li>
                De lessen worden op een vaste dag en tijd gepland. Indien nodig
                kan hiervan worden afgeweken in overleg. Wijzigingen worden
                vastgelegd tijdens de les, per app of per mail.
              </li>
              <li>
                Bij verhindering van de leerling wordt het lesgeld niet in
                rekening gebracht wanneer de leerling binnen 24 uur belt, appt
                of mailt om de les te annuleren of te verzetten binnen een
                maand. Wordt hier niet aan voldaan dan wordt de les in rekening
                gebracht.
              </li>
              <li>
                Bij verhindering van de docent wordt het lesgeld niet in
                rekening gebracht en wordt de les op een later moment ingehaald.
              </li>
            </ul>
          </Terms>
        </Column>
        <Column>
          <Terms>
            <H3>Voorwaarden betaling</H3>
            <ul>
              <li>
                De leerling ontvangt een factuur per mail of een betaalverzoek.
              </li>
              <li>
                De lessen worden <strong>vooraf per maand</strong> betaald.
              </li>
              <li>
                Na betaling heeft de leerling recht op de lessen en gaat akkoord
                met de voorwaarden.
              </li>
              <li>
                Terugbetaling van lesgeld is niet mogelijk. In uitzonderlijke
                gevallen, zoals langdurige ziekte, kan in overleg een regeling
                worden getroffen.
              </li>
            </ul>
          </Terms>
        </Column>
      </Section>

      <MediaBottom videoId="PFC4XlRJ5Us" />
    </Main>
  );
};
